<template>
  <div class="contents-area about-contents">
    <div
      class="about-first-screen dark"
      :style="
        'background-image: url(//' +
        serverLink +
        listSettings.aboutSettings.headerpic +
        ')'
      "
    >
      <div class="container">
        <div class="h1-breadcrumbs">
          <h1>{{ listSettings.aboutSettings.title }}</h1>
          <Breadcrumbs />
        </div>
      </div>
    </div>
    <div class="about-anons grey">
      <div class="container">
        <RichTextContents :text="listSettings.aboutSettings.text"/>
      </div>
    </div>
    <div class="contacts-map">
      <div class="contacts-area">
        <div>
          <h4>{{ translations("address") }}</h4>
          <p>{{ listContacts.address[listAppLanguage] }}</p>
          <p>{{ listSettings.sitetitle }}</p>
        </div>
        <div>
          <h4>{{ translations("Phone") }}</h4>
          <p>{{ listContacts.phone }}</p>
          <p>{{ listContacts.phone2 }}</p>
        </div>
        <div>
          <h4>{{ translations("mail") }}</h4>
          <p>{{ listContacts.email }}</p>
        </div>
      </div>
      <div class="map-area" v-if="showMap">
        <yandex-map :coords="mapCoords" :zoom="mapZoom" :scrollZoom="false">
          <ymap-marker
            :coords="mapCoords"
            marker-id="123"
            :hint-content="listSettings.sitetitle"
            @map-was-initialized="mapInitHandler"
          />
        </yandex-map>
      </div>
    </div>
    <div
      class="consult-block"
      :style="
        'background-image: linear-gradient(0deg, rgba(92, 107, 171, 0.3), rgba(92, 107, 171, 0.3)), url(//' +
        serverLink +
        listSettings.aboutSettings.footerpic +
        ')'
      "
    >
      <div class="consult-cont">
        <ContactForm />
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters } from "vuex";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import ContactForm from "@/components/app/forms/ContactForm";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import RichTextContents from "@/components/RichTextContents";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "About",
  metaInfo() {
    return {
      title:
        this.listSettings.aboutSettings.meta_title +
        " | " +
        this.listLang.site_name,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.listSettings.aboutSettings.meta_desc,
        },
        {
          name: "keywords",
          content: this.listSettings.aboutSettings.meta_keywords,
        },
      ],
    };
  },
  components: {
    yandexMap,
    ymapMarker,
    ContactForm,
    RichTextContents
  },
  data: () => ({
    mapCoords: [],
    mapZoom: 17,
    showMap: false,
    serverLink: VUE_APP_BACK_URL,
  }),
  computed: {
    ...mapGetters([
      "listSettings",
      "listLang",
      "listContacts",
      "translations",
      "listAppLanguage",
      "ListLoggedUser",
      "isUserLogged",
    ]),
  },
  methods: {
    mapInitHandler(payload) {
      console.log("Map", payload);
    },
  },
  mounted() {
    setTimeout(() => {
      this.mapCoords = [
        this.listSettings.mapSettings.center_latitude,
        this.listSettings.mapSettings.center_longitude,
      ];
      this.mapZoom = this.listSettings.mapSettings.zoom;
      this.showMap = true;

      setTimeout(() => {
        gsap.from(".map-area", {
          scrollTrigger: {
            trigger: ".contacts-map",
            start: 0,
            end: "+=350",
            scrub: 0.4,
            marks: true,
          },
          right: -500,
          ease: "none",
          duration: 2,
        });
      }, 500);
    }, 1000);

    gsap.to(".about-first-screen", {
      scrollTrigger: {
        start: "top top",
        end: "+=400",
        scrub: 1,
      },
      backgroundPositionY: 100,
      ease: "none",
      duration: 4,
    });

    gsap.from(".consult-block .consult-cont", {
      scrollTrigger: {
        trigger: ".consult-block",
        start: "top bottom",
        end: "+=850",
        scrub: 0.5,
      },
      left: 230,
      opacity: 0.8,
      ease: "none",
      duration: 2,
    });

    gsap.from(".contacts-map .contacts-area > div:first-of-type", {
      scrollTrigger: {
        trigger: ".contacts-map",
        start: "top bottom",
        end: "+=550",
        scrub: 0.5,
      },
      left: 200,
      opacity: 0,
      ease: "none",
      duration: 2,
    });
    gsap.from(".contacts-map .contacts-area > div:nth-of-type(2)", {
      scrollTrigger: {
        trigger: ".contacts-map",
        start: "top bottom",
        end: "+=550",
        scrub: 1,
      },
      left: 200,
      opacity: 0,
      ease: "none",
      duration: 2,
    });
    gsap.from(".contacts-map .contacts-area > div:nth-of-type(3)", {
      scrollTrigger: {
        trigger: ".contacts-map",
        start: "top bottom",
        end: "+=550",
        scrub: 1.5,
      },
      left: 200,
      opacity: 0,
      ease: "none",
      duration: 2,
    });
  },
};
</script>
<style>
.ymap-container {
  height: 500px;
}
</style>