<template>
  <div class="seo-content" :class="containerClass" v-html="text" :id="id"></div>
</template>

<script>
export default {
  name: "RichTextContents",
  data() {
    return { id: null };
  },
  props: {
    text: {
      type: String,
    },
    containerClass: {
      type: String,
    },
  },
  methods: {
    handleLinkClick(e) {
      e.preventDefault();
      console.log("link", e.target.getAttribute("href"));
      this.$router.push(e.target.getAttribute("href"));
    },
  },
  created() {
    this.id = this._uid;
  },
  mounted() {
    document
      .getElementById(this.id)
      .querySelectorAll("a")
      .forEach((el) => {
        el.addEventListener("click", this.handleLinkClick);
      });
  },
  destroyed() {
    document
      .getElementById(this.id)
      .querySelectorAll("a")
      .forEach((el) => {
        el.removeEventListener("click", this.handleLinkClick);
      });
  },
};
</script>
